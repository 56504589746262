import {
  Box,
  BoxProps,
  Flex,
  Container,
  HStack,
  IconButton,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import Image from 'next/image';

import ModalTrigger from '@inspire/ui/chakra/modal/ModalTrigger';
import LogoSrc from '@inspire/ui/assets/logo.svg';
import { ChevronDownIcon, SearchIcon } from '@inspire/ui/icons';

import Link from 'components/general/Link';
import LinkButton from 'components/general/LinkButton';
import DrawerButton from 'components/layout/DrawerButton';
import UserMenu from 'components/layout/UserMenu';
import SearchModalContent from 'components/search/SearchModalContent';
import {
  useIsEclaireurProfileComplete,
  useIsQuestionnaireComplete,
  useUser,
} from 'lib/hooks';
import {
  ARTICLES_PATH,
  CONNEXION_PATH,
  // INSCRIPTION_PATH,
  LOGGED_IN_HOME_PATH,
  LOGGED_OUT_HOME_PATH,
  PISTES_PATH,
  PRINTEMPS_ORIENTATION_PATH,
  KIT_DE_SURVIE_PATH,
  LANDING_PAGE_LYCEEN,
  LANDING_PAGE_ETUDIANT,
  LANDING_PAGE_EQUIPE_PEDAGOGIQUE,
} from 'lib/paths';
import {
  GTM_ACTION_INSCRIPTION,
  GTM_CATEGORY_INSCRIPTION,
  pushEvent,
} from 'lib/gtm';
import { useRouter } from 'next/router';
import { hideIfNarrowerThan } from 'styles/style-utils';
import { roundButtonProps } from '@inspire/ui/chakra/theme';
import { DemoTooltip, isDemo } from 'components/auth/DemoLogin';
import { accessSSO } from 'lib/sso';

const MidNavLink = ({ children, href, extraMatchRule = null, ...props }) => {
  const { asPath } = useRouter();
  const isMatch = href === asPath || extraMatchRule?.(asPath);

  return (
    <LinkButton
      href={href}
      variant="ghost"
      color="black"
      textTransform="uppercase"
      fontSize="14px"
      fontWeight="bold"
      size="sm"
      px={[1.5, null, 2, 3]}
      bg={isMatch ? 'yellow.200' : undefined}
      {...props}
    >
      {children}
    </LinkButton>
  );
};

const MidNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <HStack spacing={[0, null, 1, 2]} mx={2}>
      <MidNavLink href={LANDING_PAGE_LYCEEN}>Lycéen</MidNavLink>
      <MidNavLink href={LANDING_PAGE_EQUIPE_PEDAGOGIQUE}>
        Équipe pédagogique
      </MidNavLink>
      <MidNavLink href={LANDING_PAGE_ETUDIANT}>Étudiant</MidNavLink>

      <Box onMouseLeave={onClose}>
        <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
          <MenuButton
            bg="white"
            _hover={{ bg: 'white' }}
            _active={{ bg: 'white' }}
            onMouseEnter={onOpen}
            as={Button}
            rightIcon={
              <Icon
                as={ChevronDownIcon}
                boxSize={6}
                sx={hideIfNarrowerThan('870px')}
              />
            }
            textTransform="uppercase"
            fontSize="14px"
            fontWeight="bold"
            px={2}
            sx={{ span: { mr: 0.5 } }}
          >
            Ressources
          </MenuButton>
          <MenuList mt="-12px" borderTopRadius={0}>
            <NextLink href={PISTES_PATH} passHref>
              <MenuItem
                textTransform="uppercase"
                fontSize="14px"
                fontWeight="bold"
              >
                Pistes d'études
              </MenuItem>
            </NextLink>
            <NextLink href={KIT_DE_SURVIE_PATH} passHref>
              <MenuItem
                textTransform="uppercase"
                fontSize="14px"
                fontWeight="bold"
              >
                Kit de survie
              </MenuItem>
            </NextLink>
            <NextLink href={ARTICLES_PATH} passHref>
              <MenuItem
                textTransform="uppercase"
                fontSize="14px"
                fontWeight="bold"
              >
                Blog
              </MenuItem>
            </NextLink>
            {process.env.NEXT_PUBLIC_ENABLE_PRINTEMPS_ORIENTATION && (
              <NextLink href={PRINTEMPS_ORIENTATION_PATH} passHref>
                <MenuItem
                  textTransform="uppercase"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  Printemps de l'orientation
                </MenuItem>
              </NextLink>
            )}
            {/* <NextLink href={FAQ_PATH} passHref>
              <MenuItem
                textTransform="uppercase"
                fontSize="14px"
                fontWeight="bold"
              >
                FAQ
              </MenuItem>
            </NextLink> */}
          </MenuList>
        </Menu>
      </Box>
    </HStack>
  );
};

export const SimpleDesktopHeader = (
  boxProps: BoxProps & { showLoginButton: boolean }
) => {
  const { user } = useUser();
  const isQuestionnaireComplete = useIsQuestionnaireComplete();
  const isEclaireurProfileComplete = useIsEclaireurProfileComplete();
  const { showLoginButton, ...rest } = boxProps;

  return (
    <Box
      boxShadow="0 1px 8px rgb(0 0 0 / 10%)"
      position="fixed"
      top={0}
      w="100%"
      zIndex={3}
      background="white"
      textAlign="center"
      {...rest}
    >
      <Container maxW="container.xl">
        <Flex justifyContent="space-between" alignItems="center" py={3}>
          <HStack mr={2}>
            {user && isEclaireurProfileComplete && <DrawerButton />}
            <Link
              href={user ? LOGGED_IN_HOME_PATH : LOGGED_OUT_HOME_PATH}
              lineHeight={0}
            >
              <Image alt="Logo INSPIRE" src={LogoSrc} width={150} height={52} />
            </Link>
          </HStack>
          {isQuestionnaireComplete && isEclaireurProfileComplete && <MidNav />}
          <HStack>
            {!user && (
              <>
                {showLoginButton && (
                  <DemoTooltip>
                    <Button
                      isDisabled={isDemo}
                      data-test="inscription-header"
                      {...roundButtonProps}
                      onClick={() => {
                        pushEvent(
                          GTM_CATEGORY_INSCRIPTION,
                          GTM_ACTION_INSCRIPTION,
                          "Je m'inscris (CTA 1)"
                        );
                        accessSSO({
                          targetUrl: CONNEXION_PATH,
                        });
                      }}
                    >
                      Connexion | Inscription
                    </Button>
                  </DemoTooltip>
                )}
              </>
            )}
            {isQuestionnaireComplete && isEclaireurProfileComplete && (
              <ModalTrigger
                trigger={(openModal) => (
                  <IconButton
                    data-cy="open-search-dialog"
                    variant="ghost"
                    aria-label="Recherche"
                    color="black"
                    icon={<Icon boxSize={6} as={SearchIcon} />}
                    onClick={openModal}
                    {...roundButtonProps}
                  />
                )}
                modalProps={{ size: '2xl' }}
              >
                {(closeModal) => <SearchModalContent closeModal={closeModal} />}
              </ModalTrigger>
            )}
            {user && <UserMenu />}
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export const SimpleHeaderOnDesktop = ({ showLoginButton = true }) => (
  <SimpleDesktopHeader
    display={['none', null, 'block']}
    showLoginButton={showLoginButton}
  />
);
