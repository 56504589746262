import { memo } from 'react';

import {
  Box,
  BoxProps,
  Flex,
  Container,
  ContainerProps,
} from '@chakra-ui/react';

import Banner from '@inspire/ui/chakra/layout/Banner';
import Row, { RowVariant, RowProps } from '@inspire/ui/chakra/layout/Row';
import CenterVertically from '@inspire/ui/chakra/layout/CenterVertically';
import ConditionalWrapper from '@inspire/ui/general/ConditionalWrapper';
import {
  darkBg,
  lightGrayBg,
  mainContainerMinHeightValues,
} from '@inspire/ui/static';

import { MobileHeaderOnMobile } from 'components/layout/MobileHeader';
import Footer from 'components/layout/Footer';
import { SimpleHeaderOnDesktop } from 'components/layout/SimpleLayout';
import {
  AppHeaderOnDesktop,
  AppSideNavOnDesktop,
} from 'components/layout/AppLayout';
import { useUser } from 'lib/hooks';

const appGutterPadding = [null, null, 30];

interface Props {
  variant?: 'app' | 'simple' | 'adaptative' | 'minimal';
  rowVariant?: RowVariant;
  centerVertically?: boolean;
  containerWidth?: 'xs' | 'sm' | 'md' | 'lg';
  containerProps?: ContainerProps;
  rowProps?: RowProps;
  hideFooter?: boolean;
  withMainRow?: boolean;
  showLoginButton?: boolean;
}

// Layout handles all variants and device sizes in a single component
// in order to only have a single `children` which prevents content
// duplication. `children` is contained in Main, which is the
// part of the layout that is always present in the DOM, and we
// render conditionally or with media queries the rest of the
// interface around it. The highest-level element is a horizontal flexbox
// which can have a sidenav or not, and a "right section", which is
// the main part of the page. Everything, including the headers and footers
// are in this "right section", but on mobile and in simple layout pages
// there is no nav, so it simply looks like a single column.

const Layout = ({
  mode,
  children,
  rowVariant,
  centerVertically,
  containerWidth,
  containerProps,
  rowProps,
  hideFooter,
  withMainRow = true,
  showLoginButton = true,
  ...contentBoxProps
}: Props & BoxProps & { mode: string; withMainRow?: boolean }) => {
  const Main = () => (
    <Row
      flex={1}
      variant={rowVariant}
      {...rowProps}
      // To push the footer under the fold, minus the height of the header
      minH={
        mode === 'app'
          ? 'calc(100vh - 64px)'
          : mode === 'minimal'
          ? '100vh'
          : undefined
      }
    >
      <Box
        pt={['56px', '64px', mode === 'app' && 20]} // Top bar compensation
      >
        <Banner />
        <Container
          pl={mode === 'app' && appGutterPadding}
          minH={mainContainerMinHeightValues}
          maxW={`container.${
            containerWidth ??
            (mode === 'app' || mode === 'minimal' ? 'lg' : 'xl')
          }`}
          {...containerProps}
        >
          <ConditionalWrapper
            condition={centerVertically}
            thenWrapper={(__children) => (
              <CenterVertically topOffset>{__children}</CenterVertically>
            )}
          >
            <Box py={[6, null, 10]} {...contentBoxProps}>
              {children}
            </Box>
          </ConditionalWrapper>
        </Container>
      </Box>
    </Row>
  );

  return (
    <Flex minH="100vh">
      {mode === 'app' && <AppSideNavOnDesktop />}
      {/* This background is visible on tall screens, match the footer  */}
      {/* The overflow:auto is here to constrain tables or other contents
        that stretch out the main content area.
        It has to be applied to the first descendant of the Flex container.
        https://stackoverflow.com/a/66757771/9580689 */}
      <Box
        flex={1}
        overflow="auto"
        backgroundColor={mode === 'app' ? lightGrayBg : darkBg}
      >
        <MobileHeaderOnMobile />
        {(mode === 'simple' || mode === 'minimal') && (
          <SimpleHeaderOnDesktop showLoginButton={showLoginButton} />
        )}
        {mode === 'app' && <AppHeaderOnDesktop />}
        {withMainRow ? <Main /> : children}
        {mode === 'app' && !hideFooter && (
          <Footer variant="gray" pl={appGutterPadding} />
        )}
        {mode === 'simple' && !hideFooter && <Footer />}
      </Box>
    </Flex>
  );
};

const LayoutMemo = memo(Layout);

const LayoutContainer = (props: Props & BoxProps) => {
  const { user } = useUser();
  const mode =
    props.variant === 'adaptative'
      ? user
        ? 'app'
        : 'simple'
      : props.variant ?? 'simple';

  const { showLoginButton, ...rest } = props;
  return <LayoutMemo {...rest} mode={mode} showLoginButton={showLoginButton} />;
};

export default LayoutContainer;
